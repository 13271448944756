<template>
  <div class="container">
    <van-card
      num="1"
      origin-price="120.00"
      price="8.00"
      desc="Goods descript"
      title="Goods Title"
      thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
    />
    <van-cell-group style="margin: 20px 0">
      <van-cell title="Goods Price" value="JPY 90" />
      <van-cell title="Shipping Price" value="JPY 5" />
      <van-cell title="Tax" value="JPY 5" />
      <van-cell title="Total" :value="`JPY ${orderPrice}`" />
      <template v-if="isCVS">
        <van-field
          v-model="form.name"
          label="Name"
          maxlength="20"
          input-align="right"
          placeholder="Please iput name"
        />
        <van-field
          v-model="form.surname"
          label="Surname"
          maxlength="20"
          input-align="right"
          placeholder="Please iput surname"
        />
        <van-field
          v-model="form.tel"
          label="Tel"
          type="tel"
          maxlength="11"
          input-align="right"
          placeholder="Please iput phone"
        />
        <van-field
          v-model="form.mail"
          label="Mail"
          maxlength="128"
          type="mail"
          input-align="right"
          placeholder="Please iput E-mail"
        />
      </template>
    </van-cell-group>

    <van-radio-group v-model="walletBrandName">
      <van-cell-group
        title="Selection payment method"
        v-if="presentmentMode === '02'"
      >
        <van-cell title="" clickable @click="walletBrandName = ''">
          <template #title>
            <div class="flex">
              <img :src="ailpayLogo" />
            </div>
          </template>
          <template #right-icon>
            <div class="flex">
              <div class="logos">
                <img
                  v-for="item in brands"
                  :key="item.value"
                  :src="item.logo"
                />
              </div>
              <van-radio name="" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        title="Selection payment method"
        v-else-if="presentmentMode === '03'"
      >
        <van-cell
          v-for="item in brands"
          :key="item.paymentMethodType"
          title=""
          clickable
          @click="walletBrandName = item.paymentMethodType"
        >
          <template #title>
            <div class="flex">
              <div class="logos cell-logos">
                <img
                  v-for="logo in item.logos"
                  :key="logo.logoName"
                  :src="logo.logoUrl"
                />
              </div>
            </div>
          </template>
          <template #right-icon>
            <div class="flex">
              <span style="margin-right: 5px">{{ promos }}</span>
              <van-radio :name="item.paymentMethodType" style="width: 35px" />
            </div>
          </template>
        </van-cell>
        <!-- <van-cell
            title=""
            clickable
            @click="walletBrandName = wechatPay.value"
          >
            <template #title>
              <div class="flex">
                <img :src="wechatPay.logo" alt="" />
                <span>{{ wechatPay.walletBrandName }}</span>
              </div>
            </template>
            <template #right-icon>
              <van-radio :name="wechatPay.value" />
            </template>
          </van-cell> -->
      </van-cell-group>
      <van-cell-group title="Selection payment method" v-else>
        <van-cell
          v-for="item in brands"
          :key="item.value"
          title=""
          clickable
          @click="walletBrandName = item.value"
        >
          <template #title>
            <div class="flex">
              <img :src="item.logo" alt="" />
              <span>{{ item.walletBrandName }}</span>
            </div>
          </template>
          <template #right-icon>
            <img :src="ailpayLogo" class="ailpay-logo" />
            <van-radio :name="item.value" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>

    <van-submit-bar
      :price="10000"
      button-text="Submit"
      text-align="left"
      label="Total: "
      :loading="loading"
      @submit="handlePay"
    />
  </div>
</template>

<script>
import { openCreate, openConsult, getConfig, openCreateV2 } from '@/apis'
import { CVS, TELBILL, osType } from '@/utils'
import store from '@/store'

export default {
  name: 'Confirmation',
  data() {
    return {
      brandName: '',
      loading: false,
      walletBrandName: '',
      presentmentMode: '',
      ailpayLogo: '',
      orderPrice: 100,
      brands: [],
      form: {},
      promos: '',
    }
  },
  computed: {
    brand() {
      return this.brands.find((item) => {
        return (
          this.walletBrandName === item.value ||
          this.walletBrandName === item.paymentMethodType
        )
      })
    },
    isCVS() {
      const { brandType } = this.$route.query
      return CVS.includes(brandType)
    },
    isTELBILL() {
      const { brandType } = this.$route.query
      return TELBILL.includes(brandType)
    },
  },
  created() {
    this.presentmentMode = this.$route.query.presentmentMode
    this.initForm()
    this.getOpenConsult()
  },
  methods: {
    initForm() {
      const { walletBrandName, orderPrice } = this
      const { presentmentMode, brandType } = this.$route.query
      const isBrandCashier = Number(this.$route.query.isBrandCashier || 1)
      const clientOrderNo = new Date().getTime()
      console.log(clientOrderNo)
      localStorage.setItem('clientOrderNo', clientOrderNo)
      const reUrl =
        'https://demo.ctshop.colossaltech.cn/#/dummytools/m/result?clientOrderNo=' +
        clientOrderNo
      this.form = {
        brandName: '',
        brandType: brandType || (walletBrandName === 'wechat' ? '02' : '91'),
        cancelUrl:
          brandType === '12' ||
          brandType === '92' ||
          this.isCVS ||
          this.isTELBILL
            ? 'https://demo.ctshop.colossaltech.cn/#/fail?status=cancel'
            : undefined,
        clientOrderNo,
        errorUrl:
          brandType === '12' ||
          brandType === '92' ||
          this.isCVS ||
          this.isTELBILL
            ? 'https://demo.ctshop.colossaltech.cn/#/fail?status=error'
            : undefined,
        isBrandCashier,
        isSync: 1,
        mail: '',
        name: '',
        orderPrice,
        orderSubject: 'Test Order',
        osType: osType,
        payType: '06',
        presentmentMode,
        redirectUrl: reUrl,
        surname: '',
        tel: '',
        terminalType: '03',
        token: '',
        tokenKey: '',
        walletBrandName: walletBrandName === 'wechat' ? '' : walletBrandName,
      }
    },
    getOpenConsult() {
      const orderPrice = this.orderPrice
      const { presentmentMode, terminalType } = this.form
      openConsult({
        orderPrice,
        osType,
        presentmentMode,
        terminalType,
      }).then((res) => {
        if (presentmentMode === '01' || presentmentMode === '02') {
          this.ailpayLogo = res.paymentOptions[0].logo.logoUrl
          this.brands =
            res.paymentOptions[0].paymentOptionDetail.connectWallet.supportWallets.map(
              (item) => {
                const brand = {
                  walletBrandName: item.walletBrandName,
                  value: item.walletName,
                  logo: item.walletLogo.logoUrl,
                }
                return brand
              }
            )
        } else if (presentmentMode === '03') {
          this.promos = res.promos
          this.brands = res.paymentOptions.filter(
            (item) => item.enabled === 'true'
          )
        }
      })
    },
    handlePay() {
      if (
        (this.presentmentMode === '01' || this.presentmentMode === '03') &&
        !this.walletBrandName
      ) {
        this.$toast.fail('Please select a payment method')
        return
      }
      if (this.form.brandType === '92' && this.form.isBrandCashier === 0) {
        this.loading = true
        store.dispatch('setForm', this.form)
        this.getConfig()
      } else {
        this.getConsultPayment()
      }
    },
    async getConfig() {
      const timeStamp = new Date().getTime()
      const { merchantId, serviceId } = await getConfig({ timeStamp })
      this.loading = false
      this.$router.push({
        path: '/credit',
        query: {
          merchantId,
          serviceId,
        },
      })
    },
    getConsultPayment() {
      if (this.presentmentMode === '03') {
        this.form.brandName = this.brand.brandName
      }
      this.form.walletBrandName = this.walletBrandName
      if (
        this.isTELBILL ||
        this.isCVS ||
        (this.form.brandType === '92' && this.form.isBrandCashier === 0) ||
        this.form.brandType === '93'
      ) {
        openCreateV2(this.form)
          .then((res) => {
            this.afterCreate(res)
          })
          .catch((_) => {
            this.loading = false
          })
      } else {
        openCreate(this.form)
          .then((res) => {
            this.afterCreate(res)
          })
          .catch((_) => {
            this.loading = false
          })
      }
    },
    afterCreate(res) {
      const { walletBrandName } = this
      const { isBrandCashier, brandType } = this.form
      localStorage.setItem('clientOrderNo', res.clientOrderNo)
      // if (
      //   brandType === '12' ||
      //   brandType === '92' ||
      //   this.isCVS ||
      //   this.isTELBILL
      // ) {
      //   const form = document.createElement('div')
      //   form.style.display = 'none'
      //   document.body.append(form)
      //   form.innerHTML = decodeURIComponent(res.codeUrl)
      //   form.querySelector('form').submit()
      // } else if (walletBrandName === 'wechat' || isBrandCashier === 0) {
      //   this.visible = true
      //   this.codeUrl = res.codeUrl
      //   this.timer = setInterval(this.getResult, 3000)
      // } else {
      //   location.href = res.codeUrl
      // }
      location.href = res.codeUrl
      this.loading = false
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding-bottom: 60px;
}
.flex {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.van-cell {
  align-items: center;
}
.van-cell-group img {
  margin-right: 10px;
  height: 30px;
  &.ailpay-logo {
    height: 20px;
  }
}
.van-submit-bar__button {
  width: auto;
  padding: 0 23px;
}
.logos {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-submit-bar {
  background: #010101;
  color: #fff;
  .van-submit-bar__text {
    color: #fff;
  }
  .van-submit-bar__price {
    color: #f56723;
  }
  .van-button {
    background: #e24a45;
    border-radius: 0;
  }
}
</style>
